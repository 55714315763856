import { defineStore } from 'pinia'
import { useAuthStore } from './auth'
import { ECOMMERCE_PLATFORMS, PAYMENT_PLATFORMS } from '~/constants/onboarding'
import type {
  ExpenseAccountType,
  PlatformConfig,
  TaxRateType,
} from '~/shared/interfaces'
import { useProfileStore } from '@/stores/profile'

interface RequestStatus {
  count: boolean
  data: boolean
}

interface OpenBanking {
  request: RequestStatus
  data: null
  count: number
}

interface Ecommerce {
  request: RequestStatus
  data: null
  count: any[]
}

interface Payments {
  request: RequestStatus
  data: null
  count: any[]
}

interface ConnectedAccountingPlatforms {
  data: any[]
  count: number
  isLoading: boolean
}

interface DefaultStateType {
  connectionStatus: boolean
  expenseCategories: ExpenseAccountType[]
  taxes: TaxRateType[]
  openbanking: OpenBanking
  ecommerce: Ecommerce
  payments: Payments
  selectedPlatform: PlatformConfig | undefined
  connectedAccountingPlatforms: ConnectedAccountingPlatforms
}

interface ConnectionsCountType {
  platform?: string
  sellerId?: string
  src?: string
  $platform?: string
}

const defaultState: () => DefaultStateType = () => ({
  connectionStatus: false,
  selectedPlatform: undefined,
  expenseCategories: [],
  taxes: [],
  openbanking: {
    request: { count: false, data: false },
    data: null,
    count: 0,
  },
  ecommerce: {
    request: { count: false, data: false },
    data: null,
    count: [],
  },
  payments: {
    request: { count: false, data: false },
    data: null,
    count: [],
  },
  connectedAccountingPlatforms: {
    data: [],
    count: 0,
    isLoading: true,
  },
})

async function getConnectionsCount({
  platform,
  sellerId,
  src,
  $platform,
}: ConnectionsCountType) {
  const { $rutterApi, $backMarketApi } = useNuxtApp()

  let provider = $rutterApi
  let path = 'Connections'
  if (src === 'backmarket') {
    provider = $backMarketApi
    path = 'Credentials'
  }

  try {
    const { data } = await provider.get(
      `/${path}/${sellerId}/count${platform ? `?platform=${platform}` : ''}`,
    )
    return { platform: $platform, data }
  } catch (error: any) {
    return { platform: $platform, error: error.response?.data, data: 0 }
  }
}

export const useConnectionsStore = defineStore('connections', {
  state: defaultState,
  actions: {
    setSelectedPlatform(platform: PlatformConfig | undefined) {
      this.selectedPlatform = platform
    },
    resetSelectedPlatform() {
      this.selectedPlatform = undefined
    },
    fetchAuthAndApiData() {
      const { authUser } = useAuthStore()
      const { $lenkieApi, $lenkieBankingApi } = useNuxtApp()

      let sellerId
      if (authUser) {
        sellerId = authUser.profile?.sid
      }

      return { authUser, $lenkieApi, $lenkieBankingApi, sellerId }
    },

    setOpenBanking(payload: OpenBanking) {
      this.openbanking = payload
    },

    setPayments(payload: Payments) {
      this.payments = payload
    },

    setEcommerce(payload: any[]) {
      this.ecommerce = {
        ...this.ecommerce,
        request: { ...this.ecommerce.request, count: true },
        count: payload,
      }
    },

    async setOpenBankingCount() {
      const { authUser, $lenkieApi, sellerId } = this.fetchAuthAndApiData()
      const profileStore = useProfileStore()

      if (!authUser || !authUser.profile) {
        return
      }

      if (!sellerId) {
        this.openbanking = {
          ...this.openbanking,
          request: { ...this.openbanking.request, count: true },
          count: 0,
        }
        return
      }

      try {
        const { data } = await $lenkieApi.get(
          `/Organisations/${profileStore.organisationId}/BankAccounts/Count`,
        )
        this.openbanking = {
          ...this.openbanking,
          request: { ...this.openbanking.request, count: true },
          count: data,
        }
      } catch (error) {}
    },

    async setEcommerceCount() {
      const { authUser, sellerId } = this.fetchAuthAndApiData()

      if (!authUser || !authUser.profile) {
        return
      }

      if (!sellerId) {
        return (this.ecommerce = {
          ...this.ecommerce,
          request: { ...this.ecommerce.request, count: true },
          count: [],
        })
      }

      const data = await Promise.all(
        ECOMMERCE_PLATFORMS.map(async (ec) => {
          let platform: string | undefined = ec.name
          let src
          if (platform.toLowerCase() === 'backmarket') {
            platform = undefined
            src = 'backmarket'
          }

          return await getConnectionsCount.call(this, {
            sellerId,
            platform,
            $platform: ec.name,
            src,
          })
        }),
      )

      this.ecommerce = {
        ...this.ecommerce,
        request: { ...this.ecommerce.request, count: true },
        count: data,
      }
    },

    async setPaymentsCount() {
      const { sellerId } = this.fetchAuthAndApiData()

      try {
        if (!sellerId) {
          return (this.payments = {
            ...this.payments,
            request: { ...this.payments.request, count: true },
            count: [],
          })
        }
        const data = await Promise.all(
          PAYMENT_PLATFORMS.map(async (ec) => {
            const platform = ec.title
            return await getConnectionsCount.call(this, {
              sellerId,
              platform,
            })
          }),
        )
        this.payments = {
          ...this.payments,
          request: { ...this.payments.request, count: true },
          count: data,
        }
      } catch (error) {}
    },

    async getConnectedAccountingPlatforms() {
      const profileStore = useProfileStore()
      const { $lenkieBankingApi } = this.fetchAuthAndApiData()

      if (!profileStore.organisationId) {
        return
      }

      try {
        this.connectedAccountingPlatforms.isLoading = true

        const endpoint = `/Accounting/Connections/${profileStore.organisationId}`
        const response = await $lenkieBankingApi.get(endpoint)

        this.connectedAccountingPlatforms.data = response.data.result

        if (response.data.result.length > 0) {
          this.connectedAccountingPlatforms.count = 1
        }
      } catch (error: any) {
      } finally {
        this.connectedAccountingPlatforms.isLoading = false
      }
    },

    async getExpenseAccountsByOrgId(sellerId: string) {
      try {
        const { $lenkieBankingApi } = this.fetchAuthAndApiData()

        const { data } = await $lenkieBankingApi.get(
          `Bills/ExpenseAccounts/${sellerId}`,
        )
        this.expenseCategories = data
      } catch (error: any) {}
    },

    async getTaxes(sellerId: string) {
      try {
        const { $lenkieBankingApi } = this.fetchAuthAndApiData()

        const { data } = await $lenkieBankingApi.get(
          `Accounting/TaxRates/${sellerId}`,
        )

        this.taxes = data.result
      } catch (error: any) {}
    },
  },
  getters: {
    hasActiveAccountingPlatformConnection: (state) =>
      state.connectedAccountingPlatforms.data.some(
        (platform) => platform.status === 'Active',
      ),
  },
})
